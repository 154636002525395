import './index.css';
import '@common/i18n'
import '@assets/Fonts/HoneyScript.ttf';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const React = await import('react');
const ReactDOM = await import('react-dom/client');
const { BrowserRouter } = await import( 'react-router-dom');
const { App } = await import('./App');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter basename={process.env.REACT_APP_MORGAN_BASENAME || process.env.REACT_APP_PUBLIC_URL}>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if (process.env.REACT_APP_SERVICE_WORKER_ENABLED === 'true') {
//   console.log("Default SW being registered");
//   serviceWorkerRegistration.register();
// }  
