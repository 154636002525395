/*
==================================================================================
  This file serves as configuration for language support. Here the module is
  initialized, then exported as a module and bundled in with index.js.
  When the root of the react app is run, language control is given to any
  component app-wide.
==================================================================================
*/

import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from '../Assets/Languages/en.json';
import esTranslation from '../Assets/Languages/es.json';
import koTranslation from '../Assets/Languages/ko.json';
import viTranslation from '../Assets/Languages/vi.json';
import frTranslation from '../Assets/Languages/fr.json';
import arTranslation from '../Assets/Languages/ar.json';
import deTranslation from '../Assets/Languages/de.json';
import ruTranslation from '../Assets/Languages/ru.json';
import tlTranslation from '../Assets/Languages/tl.json';
import zhTranslation from '../Assets/Languages/zh.json';
import ptTranslation from '../Assets/Languages/pt.json';
import htTranslation from '../Assets/Languages/ht.json';
import plTranslation from '../Assets/Languages/pl.json';
import hiTranslation from '../Assets/Languages/hi.json';
import jaTranslation from '../Assets/Languages/ja.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

/*
=======================================================
*******************************************************
  SUPPORT FOR ADDITIONAL LANGUAGES MUST BE ADDED HERE.
  ENSURE THAT YOU CREATE A .JSON FILE FOR THE LANGUAGE
  IN: ../Assets/Languages/<LANGUAGE HERE>.json
*******************************************************
=======================================================
*/

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  ko: {
    translation: koTranslation,
  },
  vi: {
    translation: viTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  ar: {
    translation: arTranslation,
  },
  de: {
    translation: deTranslation,
  },
  ru: {
    translation: ruTranslation,
  },
  tl: {
    translation: tlTranslation,
  },
  zh: {
    translation: zhTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  ht: {
    translation: htTranslation,
  },
  pl: {
    translation: plTranslation,
  },
  hi: {
    translation: hiTranslation,
  },
  ja: {
    translation: jaTranslation,
  },
};


/*
=======================================================================
  Language detector will automatically detect the client's
  language and set it site-wide.

  A language can be passed as a query parameter:
  ?lng=en <--- this will set language to english
  ?lng=es <--- this does the same for spanish

  NOTE: This query parameter CAN be chained with other parameters:
  ?theme=main&?lng=es <--- Theme is main and language is spanish

  Additionally, localStorage can be used to detect/set language:
  i18nextLng: "en"  <--- this will set language to english
  i18nextLng: "es"  <--- this does the same for spanish

  When the user first accesses the app, the browser's default
  language will set in localStorage. This will persist between
  sessions.

  There are many different ways the preferred language can be detected
  For additional methods of detecting the client's language
  See: https://github.com/i18next/i18next-browser-languageDetector
 ======================================================================
*/

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
